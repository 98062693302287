@font-face {
   font-family: 'Poeti';
   font-style: normal;
   font-weight: 500;
   font-display: swap;
   src: url('./fonts/eft_paalul_light-webfont.woff') format('woff');
}

body {
   margin: 0;
}

.terms-field .MuiFormControlLabel-root {
   margin-left: 6px;
}
